// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVIrDVeEzBS5MoUnrreEhKx4dnASIgrUw",
  authDomain: "mknelectronics-prod.firebaseapp.com",
  projectId: "mknelectronics-prod",
  storageBucket: "mknelectronics-prod.appspot.com",
  messagingSenderId: "765996049927",
  appId: "1:765996049927:web:768aa9812bac841f5deca2",
  measurementId: "G-BDWD20K0P5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);