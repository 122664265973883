import React, { useState } from 'react';
import './loading.css';
const Loading = () => {

  return (
    <div className='mainDIV'>
     <div class="center">
     <div class="loader"></div>
</div>

    </div>
  );
};

export default Loading;
