// App.js
import React , { Suspense } from 'react';
import './global.css';
import ReactDOM from "react-dom/client";
import Loading from './loading';
import { BrowserRouter, Routes, Route  } from "react-router-dom";
const HomePage = React.lazy(() => import('./pages/home/home'));
const AboutPage = React.lazy(() => import('./pages/about/about_page'));
const ContactPage = React.lazy(() => import('./pages/contact/contact_page'));
const ProductsPage = React.lazy(() => import('./pages/product_page/product_page'));
const SpecialPage = React.lazy(() => import('./pages/Events Special Pages/SpecialPage'));


// import HomePage from './pages/home/home';
// import ContactPage from './pages/contact/contact_page';
// import ProductsPage from './pages/product_page/product_page';
// import SpecialPage from './pages/Events Special Pages/SpecialPage';
// import ErrorBoundary from './error_boundary';
// const LogoImage = styled.img`
//   height:65px;
//   width: 70px;
 
//   @media (min-width: 768px) {
//   height:75px;
//   width: 80px;
//   }
// `;

export default function App() {
  return (
  
      // <ErrorBoundary>
        <BrowserRouter>
         <Suspense fallback={<Loading />}>
   
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/products" element={<ProductsPage/>} />
        <Route path="/events" element={<SpecialPage/>} />
      </Routes>
      </Suspense>
    </BrowserRouter>
      // </ErrorBoundary>

  
    
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);